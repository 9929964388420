/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import CssBaseline from '@material-ui/core/CssBaseline';
import { Helmet } from "react-helmet";
import MainMenu from '../components/MainMenu';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';

import logoImage from '../images/logo2.png';
import classes from './layout.module.css';
import './main.css';

const Layout = ({ children, homepage }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <CssBaseline />
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Exo+2:100,300,400" rel="stylesheet" />
          <link rel="icon" type="image/png" href={logoImage} />
        </Helmet>
        <div className={classNames(classes.hero, homepage ? 'homepage' : '')} >
          <MainMenu />
          <Typography variant="h1" gutterBottom align="center" style={{ color: '#FFF' }} className={classes.siteTitle}>
            Advanced Web&nbsp;Apps
          </Typography>

          <Typography align="center" className={classes.siteSubtitle}>
            Consulting & custom engineered solutions for your most complex needs.
          </Typography>
          <div className={classes.block1} />
        </div>

        {children}

        <div className={classes.footer}>
          <Grid container spacing={24} justify="space-around" alignItems="center">
            <Grid item>
              <Typography variant="caption" style={{ color: 'rgba(255,255,255,.7)' }}>
                &copy; Copyright 2022 - Advanced Web Apps
              </Typography>
            </Grid>
          </Grid>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
