import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import Link from '@material-ui/core/Link';
import MenuIcon from 'mdi-material-ui/Menu';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';

import logoImage from '../images/logo2.png';
import classes from './main-menu.module.css';

const items = [
  { primary: `Projects`, to: '/#clients' },
  { primary: `Consulting Technologies`, to: '/#consulting' },
  { primary: `Non-Profits`, to: '/#non-profits' },
  { primary: `Contact Us`, to: '/#contact-us' },
];

const MenuItem = ({ primary, to }) => (
  <GatsbyLink to={to} className={classes.item}>{primary}</GatsbyLink>
);

class MainMenu extends React.Component {
  state = {
    open: false,
  }

  handleOpen = () => this.setState({ open: true })
  handleClose = () => this.setState({ open: false })

  render() {
    const { open } = this.state;

    return (
      <nav className={classes.root}>
        {items.map(({ primary, to }, i) => (
          <MenuItem key={i} primary={primary} to={to} />
        ))}
        <IconButton onClick={this.handleOpen} className={classes.menuButton}><MenuIcon /></IconButton>
        <Drawer anchor="right" open={open} onClose={this.handleClose} className={classes.drawer}>
          <Link href="/" className={classes.drawerLogo}><img src={logoImage} alt="Advanced Web Apps logo" /></Link>
          <div className={classes.drawerMenu} onClick={this.handleClose}>
            {items.map(({ primary, to }, i) => (
              <MenuItem key={i} primary={primary} to={to} />
            ))}
          </div>
        </Drawer>
      </nav>
    );
  }
}

export default MainMenu;
