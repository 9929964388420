let names = `
  access apollo arcgis aws do docker drupal eagle gatsby graphql hasura html js linux
  meteor mui nginx node pm2 postgresql qgis react tableau wordpress shopify salesforce
`;

names = names.split(/\s+/).filter(name => !!name);

module.exports = {};
names.forEach(name => {
  module.exports[name] = require(`./${name}.png`);
});