import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import logos from '../images/consulting';
import classes from './consulting.module.css';
import Grid from "@material-ui/core/Grid";

let names = `
  tableau salesforce shopify wordpress drupal access
  node js html linux nginx
  aws postgresql
  arcgis qgis
  react apollo gatsby meteor hasura docker do mui
  eagle
`;
names = names.split(/\s+/).filter(name => !!name);

const Logo = ({ src, alt }) => (
  <Grid item xs={6} sm={4} md={3} lg={2} className={classes.logo}>
    <div style={{ backgroundImage: `url(${src})` }} />
  </Grid>
)

const ConsultingPage = () => (
  // <Layout homepage>
  //   <SEO title="Advanced Web Apps" keywords={[`web`, `developer`, 'development', 'design', `app`, 'website']} />
  //   Consulting
  <Grid container spacing={40} justify="center" alignItems="center" className={classes.logos}>
    {names.map(name => <Logo key={name} src={logos[name]} alt={`${name} logo`} />)}
  </Grid>
  // </Layout >
)

export default ConsultingPage
